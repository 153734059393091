/*
 * @Description: 路由配置
 * @Author: rendc
 * @Date: 2021-04-22 15:05:32
 * @LastEditors: rendc
 * @LastEditTime: 2023-04-18 09:30:28
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../pages/Home.vue')
  },
  {
    path: '/regLogin',
    name: 'RegLogin',
    component: () => import('../pages/RegLogin.vue')
  },
  {
    path: '/exam',
    name: 'Exam',
    component: () => import('../pages/Exam.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
